import React from "react";
import { Icon } from "@chakra-ui/react";
import { MdAcUnit, MdDashboard, MdClass, MdShield, MdLocalFireDepartment } from "react-icons/md";
import { AiOutlineDeploymentUnit } from "react-icons/ai";
import { FaPersonMilitaryRifle } from "react-icons/fa6";


// Admin Imports
import MainDashboard from "views/admin/default";

import Courses from "views/admin/courses/view";
import CreateCourses from "views/admin/courses/create";
import DetailsCourses from "views/admin/courses/details";
import AssignCourses from "views/admin/courses/assign";
import UnassignCourses from "views/admin/courses/unassign";
import EditCourses from "views/admin/courses/edit";

import SoldiersView from 'views/admin/soldiers/view'
import SoldiersCreate from 'views/admin/soldiers/create'

import CourseCategory from "views/admin/course_category/view";
import CreateCourseCategory from "views/admin/course_category/create";

import DepartmentsView from "views/admin/departments/view";
import DepartmentsCreate from "views/admin/departments/create";
import DepartmentsEdit from "views/admin/departments/edit";

// Auth Imports
import SignInCentered from "views/auth/signIn";

const routes = [
  {
    name: "dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "soldiers",
    layout: "/admin",
    path: "/soldiers",
    icon: <Icon as={MdShield} width="20px" height="20px" color="inherit" />,
    component: SoldiersView,
  },
  {
    name: "soldiers",
    layout: "/admin",
    path: "/soldiers/create",
    icon: <Icon as={MdShield} width="20px" height="20px" color="inherit" />,
    component: SoldiersCreate,
    subpath: true,
  },
  {
    name: "courses",
    layout: "/admin",
    path: "/courses",
    icon: <Icon as={MdClass} width="20px" height="20px" color="inherit" />,
    component: Courses,
  },
  {
    name: "create_course",
    layout: "/admin",
    path: "/courses/create",
    component: CreateCourses,
    subpath: true,
  },
  {
    name: "details_course",
    layout: "/admin",
    path: "/courses/view",
    component: DetailsCourses,
    subpath: true,
  },
  {
    name: "unassign_course",
    layout: "/admin",
    path: "/courses/unassign",
    component: UnassignCourses,
    subpath: true,
  },
  {
    name: "assign_course",
    layout: "/admin",
    path: "/courses/assign",
    component: AssignCourses,
    subpath: true,
  },
  {
    name: "edit_course",
    layout: "/admin",
    path: "/courses/edit",
    component: EditCourses,
    subpath: true,
  },
  {
    name: "course_categories",
    layout: "/admin",
    path: "/course_category",
    icon: <Icon as={AiOutlineDeploymentUnit} width="20px" height="20px" color="inherit" />,
    component: CourseCategory,
  },
  {
    name: "create_courses_category",
    layout: "/admin",
    path: "/course_category/create",
    icon: <Icon as={MdAcUnit} width="20px" height="20px" color="inherit" />,
    component: CreateCourseCategory,
    subpath: true,
  },
  // {
  //   name: "specializations",
  //   layout: "/admin",
  //   path: "/specializations",
  //   icon: <Icon as={MdOutlineArchitecture} width="20px" height="20px" color="inherit" />,
  //   component: Courses,
  // },
  {
    name: "military_department",
    layout: "/admin",
    path: "/departments",
    icon: <Icon as={FaPersonMilitaryRifle} width="20px" height="20px" color="inherit" />,
    component: DepartmentsView,
  },
  {
    name: "create_military_department",
    layout: "/admin",
    path: "/departments/create",
    icon: <Icon as={MdLocalFireDepartment} width="20px" height="20px" color="inherit" />,
    component: DepartmentsCreate,
    subpath: true,
  },
  {
    name: "department_edit",
    layout: "/admin",
    path: "/departments/edit",
    icon: <Icon as={MdLocalFireDepartment} width="20px" height="20px" color="inherit" />,
    component: DepartmentsEdit,
    subpath: true,
  },
  // {
  //   name: "auth",
  //   layout: "/auth",
  //   path: "/login",
  //   icon: <Icon as={MdLocalFireDepartment} width="20px" height="20px" color="inherit" />,
  //   component: SignInCentered,
  // },
];

export default routes;
