// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import {
  MdGroupAdd,
  MdSchool,
  MdPeople,
  MdHowToReg,
  MdExitToApp,
} from "react-icons/md";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import {
  columnsDataCheck,
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import { useTranslation } from 'react-i18next';
import axios from 'axios'

export default function UserReports() {
  const { t, i18n } = useTranslation()
  const [numberOfSoldiers, setNumberOfSoldiers] = useState(0);
  const [numberOfCourses, setNumberOfCourses] = useState(0);
  const [numberSoilderStatusAffiliate, setNumberSoilderStatusAffiliate] = useState(0);
  const [numberSoilderStatusWithdrawn, setNumberSoilderStatusWithdrawn] = useState(0);
  const [numberSoilderStatusCandidate, setNumberSoilderStatusCandidate] = useState(0);
  const [pieChartData, setPieChartData] = useState([1, 1, 1]);

  useEffect(() => {
    const fetchStatistic = async () => {
      try {
        const res = await axios.get('/api/statics');
        const data = res.data;

        // Set state variables based on the response from /statics
        setNumberOfSoldiers(data.totalUsers || 0);
        setNumberOfCourses(data.totalCourses || 0);
        setNumberSoilderStatusAffiliate(data.soldiersPerStatus.affiliate || 0);
        setNumberSoilderStatusWithdrawn(data.soldiersPerStatus.withdrawn || 0);
        setNumberSoilderStatusCandidate(data.soldiersPerStatus.candidate || 0);
        setPieChartData([
          data.coursesPerStatus.completed, // Completed
          data.coursesPerStatus.active,     // Active
          data.coursesPerStatus.inactive     // Inactive
        ]);
      } catch (error) {
        console.error('Error fetching statistic:', error);
      }
    };

    fetchStatistic();
  }, []);

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdPeople} color={brandColor} />
              }
            />
          }
          name={t("soldiers")}
          value={numberOfSoldiers}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdSchool} color={brandColor} />
              }
            />
          }
          name={t("military_courses")}
          value={numberOfCourses}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdGroupAdd} color={brandColor} />
              }
            />
          }
          name={t("soilder_status_affiliate")}
          value={numberSoilderStatusAffiliate}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdExitToApp} color={brandColor} />
              }
            />
          }
          name={t("soilder_status_withdrawn")}
          value={numberSoilderStatusWithdrawn}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdHowToReg} color={brandColor} />
              }
            />
          }
          name={t("soilder_status_candidate")}
          value={numberSoilderStatusCandidate}
        />
        {/* <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdExitToApp} color={brandColor} />
              }
            />
          }
          name='Total Projects'
          value='2935'
        /> */}
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap='20px' mb='20px'>
        {/* <TotalSpent />
        <DailyTraffic /> */}
        <PieCard pieChartData={pieChartData} totalCourses={numberOfCourses}/>
      </SimpleGrid>
    </Box>
  );
}
