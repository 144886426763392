// Chakra imports
import { Box, Flex, Spinner } from "@chakra-ui/react";
import ColumnsTable from "./ColumnsTable";
import React, { useEffect, useState } from "react";
import axios from 'axios'

// Translation
import { useTranslation } from 'react-i18next';

export default function Settings() {
  const { t, i18n } = useTranslation()
  const [users, setUsersData] = useState(null)

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/api/users`,
    }).then(async (result) => {
      setUsersData(result.data)

    }).catch(async (err) => {
      console.log(err)
    })
  }, [i18n.language])

  if(!users) return (
    <Flex
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      height="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner
        thickness="8px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl" // You can change this to "lg" or "2xl" if you need it even bigger
      />
    </Flex>
  )

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ColumnsTable
          columnsData={[
            {
              "Header": "soldier_number",
              "accessor": "soldier_number",
              "show": true,
              "required": false
            },
            {
              "Header": "soldier_full_name",
              "accessor": "soldier_full_name",
              "show": true,
              "required": true
            },
            {
              "Header": "soldier_image",
              "accessor": "soldier_image",
              "show": false,
              "required": true
            },
            {
              "Header": "soldier_rank",
              "accessor": "soldier_rank",
              "show": true,
              "required": true
            },
            {
              "Header": "soldier_date_of_hiring",
              "accessor": "soldier_date_of_hiring",
              "show": true,
              "required": true
            },
            {
              "Header": "departments_assigned",
              "accessor": "department",
              "show": true,
              "required": true
            },
            {
              "Header": "course_categories",
              "accessor": "course_categories",
              "show": true,
              "required": true
            }
          ]}
          tableData={users}
        />
    </Box>
  );
}
