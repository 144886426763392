import React from 'react';
import ReactDOM from 'react-dom/client';
import 'assets/css/App.css';
import { BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import { RtlProvider } from './components/rtlProvider/RtlProvider';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';

import 'localization/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<RtlProvider>
		<ChakraProvider theme={theme}>
			<React.StrictMode>
				<ThemeEditorProvider>
					<Router>
						<Routes>
							<Route path="/auth/*" element={<AuthLayout />} />
							<Route path="/admin/*" element={<AdminLayout />} />
							<Route path="/" element={<Navigate  to="/admin" />} />
						</Routes>
					</Router>
				</ThemeEditorProvider>
			</React.StrictMode>
		</ChakraProvider>
	</RtlProvider>
);
