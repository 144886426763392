import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          "military_courses": "Military Courses",
          "courses": "Courses",
          "course_name": "Course Name",
          "course_id": "Course ID",
          "course_start_date": "Start Date",
          "course_end_date": "End Date",
          "page": "Page",
          "pages": "Pages",
          "dashboard": "Dashboard",
          "search": "Search",
          "trademark": "Training Project. All rights reserved.",
          "create_course": "Create Course",
          "details_course": "Course Information",
          "specializations": "Specializations",
          "soldier_number": "Soldier Number",
          "soldier_image": "Soldier Image",
          "soldier_name": "Soldier Name",
          "enrollee_list": "Enrollee List",
          "specialization": "Specialization",
          "enrollee_number": "Enrollee Number",
          "course_categories": "Course Categories",
          "edit_course_details": "Edit Course",
          "delete_course": "Delete Course",
          "edit_course": "Edit Course Information",
          "courses_in_category_progress": "Course Category / Courses",
          "courses_in_category": "Number Of Courses",
          "course_category_number": "Course Category Number",
          "create_courses_category": "Create Course Category",
          "military_course_category": "Military Course Categories",
          "course_category_creation_date": "Courses Category Creation Date",
          "department_number": "Department Number",
          "department_name": "Department Name",
          "number_of_employees_in_department": "Number Of Employees In Department",
          "military_department": "Military Department",
          "create_military_department": "Create Military Department",
          "department_delete": "Department Delete",
          "department_edit": "Edit Department",
          "soldiers": "Soldiers",
          "employees": "Employees",
          "departments_assigned": "Departments Assigned",
          "soldier_date_of_hiring": "Soldier Date Of Hiring",
          "soldier_rank": "Soldier Rank",
          "soldier_full_name": "Soldier Full Name",
          "create_soldier": "Create Soldier",
          "assign_course": "Assign Soldier To Course",
          "assign_soldier": "Assign Soldier",
          "please_select": "Please Select A Choice",
          "unassign_course": "Unassign Course From Soldier",
          "unassign_soldier": "Unassign Soldier",
          "number_of_employees_in_courses_category": "Number Of Employees In Courses Category",
          "no_courses_category": "No  Courses Categories Found",
          "no_enrollee": "No Enrollee Found",
          "no_courses": "No Courses Found",
          "course_status": "Course Status",
          "total_courses": "Total Courses",
          "course_status_active": "Held",
          "course_status_inactive": "Not held",
          "course_status_completed": "Completed",
          "soilder_status": "Soilder Status",
          "soilder_status_affiliate": "Affiliate",
          "soilder_status_withdrawn": "Withdrawn",
          "soilder_status_candidate": "Candidate",
          "course_mandatory": "Mandatory Course",
          "not_mandatory": "Not Mandatory",
          "mandatory": "Mandatory",
          "select_class": "Select Class",
          "everyone": "Everyone",
          "specific_department": "Specific Department",
          "select_duration": "Select Duration",
          "select_department": "Select Department",
          "lvl2": "Level 2",
          "lvl4": "Level 4",
          "lvl6": "Level 6",
          "lvl8": "Level 8",
        
        }
      },
      ar: {
        translation: {
          "military_courses": "الدورات العسكرية",
          "courses": "الدورات",
          "course_name": "اسم الدورة",
          "course_id": "رمز الدورة",
          "course_start_date": "تاريخ البدء",
          "course_end_date": "تاريخ الانتهاء",
          "page": "صفحة",
          "pages": "صفحات",
          "dashboard": "لوحة التحكم",
          "search": "بحث",
          "trademark": "مشروع التدريب. جميع الحقوق محفوظة.",
          "create_course": "آنشاء دورة",
          "details_course": "معلومات الدورة",
          "specializations": "التخصصات",
          "soldier_name": "اسم العسكري (رباعي)",
          "soldier_image": "صورة العسكري",
          "soldier_number": "رقم العسكري",
          "enrollee_list": "قائمة الملتحقين",
          "specialization": "التخصص",
          "enrollee_number": "عدد الملتحقين",
          "course_categories": "فئة الدورات",
          "edit_course_details": "تعديل الدورة",
          "delete_course": "حذف الدورة",
          "edit_course": "تعديل معلومات الدورة",
          "courses_in_category_progress": "فئة الدورة / الدورة",
          "courses_in_category": "عدد الدورات المسجلة",
          "course_category_number": "رقم فئة الدورة",
          "create_courses_category": "انشاء فئة الدورة جديدة",
          "military_course_category": "فئة الدورة العسكرية",
          "course_category_creation_date": "تاريخ انشاء فئة الدورة",
          "department_number": "رقم القسم",
          "department_name": "اسم القسم",
          "number_of_employees_in_department": "عدد الموظفين في القسم",
          "military_department": "الاقسام العسكرية",
          "create_military_department": "أنشاء قسم عسكري",
          "department_delete": "حذف القسم",
          "department_edit": "تعديل القسم",
          "soldiers": "جناح الشرطة",
          "employees": "الموظفين",
          "departments_assigned": "التخصص المعين عليه",
          "soldier_date_of_hiring": "تاريخ التعيين",
          "soldier_rank": "الرتبة",
          "soldier_full_name": "اسم العسكري الكامل",
          "create_soldier": "انشاء السجل العسكري",
          "assign_course": "اضافة عسكري الى دورة",
          "assign_soldier": "اضافة العسكري",
          "please_select": "الرجاء الاختيار من القائمة",
          "unassign_course": "ازالة دورة من عسكري",
          "unassign_soldier": "ازالة العسكري",
          "number_of_employees_in_courses_category": "عدد الموظفين في فئة الدورة",
          "no_courses_category": "لا يوجد سلامات تعريفية",
          "no_enrollee": "لا يوجد ملتحقين",
          "no_courses": "لا يوجد اي دورات",
          "course_status": "حالة الدورة",
          "course_status_active": "منعقده",
          "course_status_inactive": "تعذر الانعقاد",
          "course_status_completed": "مكتملة",
          "soilder_status": "حالة الحسكري",
          "soilder_status_affiliate": "ملتحق",
          "soilder_status_withdrawn": "منسحب",
          "total_courses": "عدد الدورات",
          "soilder_status_candidate": "مرشح",
          "soilder_assign_date": "تاريخ الالتحاق",
          "course_mandatory": "اجباري اتمام الدورة",
          "not_mandatory": "دورة ليست متطلبة",
          "mandatory": "دورة متطلبة",
          "select_class": "اختر شعبة",
          "everyone": "الكل",
          "specific_department": "قسم مخصص",
          "select_duration": "اختر فترة",
          "select_department": "اختر قسم",
          "lvl2": "المستوى الثاني",
          "lvl4": "المستوى الرابع",
          "lvl6": "المستوى السادس",
          "lvl8": "المستوى الثامن",
        }
      }
    }

  });

export default i18n;