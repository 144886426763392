import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    IconButton,
    Box,
    useTheme,
    Button,
    Badge
} from "@chakra-ui/react";
import React, { useMemo, useState, useEffect, useRef } from "react";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";
import FilterModal from "../../../../components/modal/Filter";

// Translation
import { useTranslation } from 'react-i18next';

import { useReactToPrint } from "react-to-print"

// Custom components
import Card from "components/card/Card";
import Plus from "components/buttons/Plus";
import DateFormatter from 'components/dateFormatter/DateFormatter';
import { SearchBar } from "components/searchBar/SearchBar";

import { FaAngleLeft, FaAngleRight, FaPrint } from "react-icons/fa6";

export default function ColumnsTable(props) {
    const theme = useTheme();
    const { t, i18n } = useTranslation()

    const contentRef = useRef(null);
    const reactToPrintFn = useReactToPrint({ contentRef, copyShadowRoots: true });

    const { columnsData, tableData } = props;
    const [searchInput, setSearchInput] = useState("");

    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 15 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { pageIndex, pageSize },
        previousPage,
        nextPage,
        setGlobalFilter,
        canPreviousPage,
        canNextPage,
    } = tableInstance;

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    useEffect(() => {
        setGlobalFilter(searchInput || undefined);
    }, [searchInput, setGlobalFilter]);

    return (
        <Card
            direction="column"
            w="100%"
            px="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
        >
            <Flex px="25px" justify="space-between" mb="20px" align="center">
                <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
                    {t("military_courses")}
                </Text>
                <Flex>
                    <SearchBar
                        mb={"unset"}
                        me="10px"
                        borderRadius="30px"
                        onChange={(e) => setSearchInput(e.target.value)}
                    />
                    <Plus href={"/admin/courses/create"} />
                </Flex>
            </Flex>
            <Table ref={contentRef} {...getTableProps()} variant="simple" color="gray.500" mb="24px">
                <Thead>
                    {headerGroups.map((headerGroup, index) => (
                        <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers
                                .filter(column => column.show)
                                .map((column, index) => (
                                    <Th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        pe="10px"
                                        key={index}
                                        borderColor={borderColor}
                                    >
                                        <Flex
                                            justify="space-between"
                                            align="center"
                                            fontSize={{ sm: "10px", lg: "12px" }}
                                            color="gray.400"
                                        >
                                            {t(column.render("Header"))}
                                        </Flex>
                                    </Th>
                                ))}
                        </Tr>

                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()} key={index} cursor="pointer" // Add cursor pointer for better UX
                                onClick={() => {
                                    // Redirect to the detailed view of the course
                                    window.location.href = `/admin/courses/view?course=${row.original.course_id}`;
                                }}>
                                {row.cells.map((cell, index) => {
                                    let data = "";
                                    if (cell.column.show) {
                                        if (cell.column.Header.includes("course_status")) {
                                            data = (
                                                <Badge variant="solid" colorScheme={cell.value === "completed" ? "green" : cell.value === "active" ? "blue" : "red"}>
                                                    {t(`course_status_${cell.value}`)}
                                                </Badge>
                                            );
                                        } else if (cell.column.Header.includes("date")) {
                                            data = (
                                                <Text color={textColor} fontSize="sm" fontWeight="700">
                                                    <DateFormatter date={cell.value} locale={i18n.language} />
                                                </Text>
                                            );
                                        } else if (cell.column.Header.includes("course_categories")) {
                                            data = (
                                                <Flex align="center">
                                                    <Text color={textColor} fontSize="sm" fontWeight="700">
                                                        {cell.value.map(si => si.course_category_number).join(', ')}
                                                    </Text>
                                                </Flex>
                                            );
                                        } else {
                                            data = (
                                                <Flex align="center">
                                                    <Text color={textColor} fontSize="sm" fontWeight="700">
                                                        {cell.value}
                                                    </Text>
                                                </Flex>
                                            );
                                        }


                                        return (
                                            <Td
                                                {...cell.getCellProps()}
                                                key={index}
                                                fontSize={{ sm: "14px" }}
                                                minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                                borderColor="transparent"
                                            >
                                                {data}
                                            </Td>
                                        );
                                    }
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
            <Flex justify="space-between" px="25px" mb="20px" align="center">
                <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
                    {t("page").toUpperCase()} {pageIndex + 1}
                </Text>
                <Flex>
                    <IconButton
                        me="10px"
                        mb="unset"
                        aria-label="Previous page"
                        borderRadius="10px"
                        icon={i18n.language === "en" ? <FaAngleLeft /> : <FaAngleRight />}
                        onClick={() => previousPage()}
                        isDisabled={!canPreviousPage}
                    />
                    <IconButton
                        me="10px"
                        mb="unset"
                        aria-label="Next page"
                        borderRadius="10px"
                        icon={i18n.language === "en" ? <FaAngleRight /> : <FaAngleLeft />}
                        onClick={() => nextPage()}
                        isDisabled={!canNextPage}
                    />
                    <IconButton
                        me="10px"
                        mb="unset"
                        borderRadius="10px"
                        icon={<FaPrint/>}
                        onClick={reactToPrintFn}
                    />

                </Flex>
            </Flex>
        </Card>
    );
}
