// Chakra imports
import { Box, Spinner, Flex } from "@chakra-ui/react";
import ColumnsTable from "./ColumnsTable";
import React, { useEffect, useState } from "react";
import axios from 'axios'

// Translation
import { useTranslation } from 'react-i18next';

export default function Settings() {
  const { t, i18n } = useTranslation()
  const [departmentData, setDepartmentData] = useState(null)

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/api/departments`,
    }).then(async (result) => {
      setDepartmentData(result.data)

    }).catch(async (err) => {
      console.log(err)
    })
  }, [i18n.language])

  if (!departmentData) return (
    <Flex
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      height="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner
        thickness="8px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl" // You can change this to "lg" or "2xl" if you need it even bigger
      />
    </Flex>
  );

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ColumnsTable
        columnsData={[
          {
            "Header": "department_number",
            "accessor": "department_number",
            "show": true,
            "required": false
          },
          {
            "Header": "department_name",
            "accessor": "department_name",
            "show": true,
            "required": true
          },
          {
            "Header": "number_of_employees_in_department",
            "accessor": "soldiers",
            "show": true,
            "required": true
          }
        ]}
        tableData={departmentData}
      />
    </Box>
  );
}
