import React from "react";
import ReactApexChart from "react-apexcharts";

class PieChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: props.chartData, // Initialize with props
      chartOptions: props.chartOptions, // Initialize with props
    };
  }

  componentDidUpdate(prevProps) {
    // Check if the props have changed
    if (prevProps.chartData !== this.props.chartData) {
      this.setState({ chartData: this.props.chartData });
    }
    
    if (prevProps.chartOptions !== this.props.chartOptions) {
      this.setState({ chartOptions: this.props.chartOptions });
    }
  }

  render() {
    return (
      <ReactApexChart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type='pie'
        width='100%'
        height='55%'
      />
    );
  }
}

export default PieChart;
