import React from 'react'
import { Button, Icon } from '@chakra-ui/react'
import { CgColorPicker } from 'react-icons/cg'
import { ImFontSize } from 'react-icons/im'
import { MdLanguage } from 'react-icons/md'

// Translation
import { useTranslation } from 'react-i18next';

export function LanguageEditor({ onOpen, navbarIcon, ...rest }) {
    const { t, i18n } = useTranslation()

  return (
    <Button
        variant='no-hover'
        bg='transparent'
        p='0px'
        minW='unset'
        minH='unset'
        h='18px'
        w='max-content'
        _focus={{ boxShadow: 'none' }}
        onClick={ () => i18n.changeLanguage(i18n.language === "en" ? "ar" : "en") }
        {...rest}
    >
        <Icon
            me='10px'
            h='18px'
            w='18px'
            color={navbarIcon}
            as={MdLanguage}
        />
      </Button>
  )
}