// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { VSeparator } from "components/separator/Separator";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import axios from 'axios';

export default function Conversion(props) {
  const { pieChartData, totalCourses, ...rest } = props;
  const { t, i18n } = useTranslation()

  const pieChartOptions = {
    labels: ["Completed", "Active", "Inactive"],
    colors: ["lightgreen", "#lightgreen", "red"],
    chart: {
      width: "50px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    fill: {
      colors: ["#4318FF", "#6AD2FF", "#EFF4FB"],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card p='20px' align='center' direction='column' w='100%' {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent='space-between'
        alignItems='center'
        w='100%'
        mb='8px'>
        <Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
          {t("courses")}
        </Text>
      </Flex>

      <PieChart
        h='100%'
        w='100%'
        chartData={pieChartData}
        chartOptions={pieChartOptions}
      />
      <Card
  bg={cardColor}
  flexDirection={{ base: 'column', md: 'row' }} // Stack on small screens, row on medium and larger
  boxShadow={cardShadow}
  w='100%'
  p='15px'
  px={{ base: '10px', md: '20px' }} // Adjust padding for mobile
  mt='15px'
  mx='auto'
>
  <Flex
    direction={{ base: 'column', md: 'row' }} // Keep vertical on small screens, horizontal on larger
    wrap='wrap' // Allow wrapping of items
    justify={{ base: 'center', md: 'space-between' }} // Center items on small screens, space out on larger
    width='100%' // Ensure it takes the full width available
  >
    <Flex direction='column' py='5px' flex='1' textAlign='center' minWidth={{ base: '100%', md: '120px' }}>
      <Flex align='center' justifyContent='center'>
        <Box h='8px' w='8px' bg='brand.500' borderRadius='50%' me='4px' />
        <Text
          fontSize={{ base: 'sm', md: 'xs' }} // Responsive font size
          color='secondaryGray.600'
          fontWeight='700'
          mb='5px'
        >
          {t("total_courses")}
        </Text>
      </Flex>
      <Text fontSize={{ base: 'xl', md: 'lg' }} color={textColor} fontWeight='700'>
        {totalCourses}
      </Text>
    </Flex>

    <Flex direction='column' py='5px' flex='1' textAlign='center' minWidth={{ base: '100%', md: '120px' }}>
      <Flex align='center' justifyContent='center'>
        <Box h='8px' w='8px' bg='lightgreen' borderRadius='50%' me='4px' />
        <Text
          fontSize={{ base: 'sm', md: 'xs' }} // Responsive font size
          color='secondaryGray.600'
          fontWeight='700'
          mb='5px'
        >
          {t("course_status_completed")}
        </Text>
      </Flex>
      <Text fontSize={{ base: 'xl', md: 'lg' }} color={textColor} fontWeight='700'>
        {((pieChartData[0] / totalCourses) * 100).toFixed(0)}%
      </Text>
    </Flex>

    <Flex direction='column' py='5px' flex='1' textAlign='center' minWidth={{ base: '100%', md: '120px' }}>
      <Flex align='center' justifyContent='center'>
        <Box h='8px' w='8px' bg='lightblue' borderRadius='50%' me='4px' />
        <Text
          fontSize={{ base: 'sm', md: 'xs' }} // Responsive font size
          color='secondaryGray.600'
          fontWeight='700'
          mb='5px'
        >
          {t("course_status_active")}
        </Text>
      </Flex>
      <Text fontSize={{ base: 'xl', md: 'lg' }} color={textColor} fontWeight='700'>
        {((pieChartData[1] / totalCourses) * 100).toFixed(0)}%
      </Text>
    </Flex>

    <Flex direction='column' py='5px' flex='1' textAlign='center' minWidth={{ base: '100%', md: '120px' }}>
      <Flex align='center' justifyContent='center'>
        <Box h='8px' w='8px' bg='red' borderRadius='50%' me='4px' />
        <Text
          fontSize={{ base: 'sm', md: 'xs' }} // Responsive font size
          color='secondaryGray.600'
          fontWeight='700'
          mb='5px'
        >
          {t("course_status_inactive")}
        </Text>
      </Flex>
      <Text fontSize={{ base: 'xl', md: 'lg' }} color={textColor} fontWeight='700'>
        {((pieChartData[2] / totalCourses) * 100).toFixed(0)}%
      </Text>
    </Flex>
  </Flex>
</Card>

    </Card>
  );
}
