import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios'

// Chakra imports
import {
  Box,
  IconButton,
  Icon,
  SimpleGrid,
  useColorModeValue,
  Flex,
  Spinner,
  Text,
  HStack,
  Button
} from "@chakra-ui/react";

// Translation
import { useTranslation } from 'react-i18next';

// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import DateFormatter from 'components/dateFormatter/DateFormatter';
import Plus from "components/buttons/Plus";

// React Toastify for notifications
import { toast, ToastContainer } from 'react-toastify';

// Table data
import ColumnsTable from "./ColumnsTable";

// Icons
import {
  MdDateRange,
  MdSafetyCheck,
  MdBarChart,
  MdTitle,
  MdEdit,
  MdDelete,
  MdCheck,
  MdError,
  MdRocketLaunch,
  MdPrint
} from "react-icons/md";
import { useReactToPrint } from "react-to-print"

export default function Settings() {

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const contentRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef, copyShadowRoots: true });

  // Chakra Color Mode
  const bgDelButton = useColorModeValue("secondaryGray.300", "red.800");
  const bgEditButton = useColorModeValue("secondaryGray.300", "blue.800");
  const [deleteDisabled, setDeleteDisabled] = useState(false)
  const bgDelHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "red.600" }
  );
  const bgEditHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "blue.500" }
  );

  async function handleDelete() {
    setDeleteDisabled(true)
    axios({
      method: 'DELETE',
      url: `/api/course/${searchParams.get("course")}`,
    }).then(async (result) => {
      navigate('/admin/courses');

    }).catch(async (err) => {
      console.log(err)
    })
  }

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const { t, i18n } = useTranslation()
  const [courseData, setCourseData] = useState(null)

  useEffect(() => {

    if (!searchParams.get("course")) return navigate('/admin/courses');

    axios({
      method: 'GET',
      url: `/api/course/${searchParams.get("course")}`,
    }).then(async (result) => {
      setCourseData(result.data)

    }).catch(async (err) => {
      console.log(err)
    })
  }, [i18n.language, searchParams.get("course")])

  // if (!courseData) return navigate('/admin/courses');

  if (!courseData) return (
    <Flex
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      height="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner
        thickness="8px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl" // You can change this to "lg" or "2xl" if you need it even bigger
      />
    </Flex>
  )

  return (
    <Box ref={contentRef} pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ToastContainer rtl={i18n.language === "ar"} />
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 1 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <Flex
              borderRadius="full"
              w='56px'
              h='56px'
              bg={
                courseData.course_status === "completed"
                  ? "green.400"
                  : courseData.course_status === "active"
                    ? "blue.400"
                    : "red.400"
              }
              color="white"
              display="flex"
              justifyContent="center" // Center the content horizontally
              alignItems="center" // Center the content vertically
              _hover={{ bg: courseData.course_status === "completed" ? "green.500" : courseData.course_status === "active" ? "blue.500" : "red.500" }}
              // _active={{ bg: courseData.course_status === "completed" ? "green.600" : courseData.course_status === "active" ? "blue.600" : "red.600" }}
            >
              <Icon
                w='32px'
                h='32px'
                as={
                  courseData.course_status === "completed"
                    ? MdCheck
                    : courseData.course_status === "active"
                      ? MdRocketLaunch
                      : MdError
                }
                color="white"
              />
            </Flex>
          }
          endContent={
            <Flex>
              <IconButton
                w='56px'
                h='56px'
                aria-label="Edit"
                borderRadius={"full"}
                bg={bgEditButton}
                _hover={bgEditHover}
                icon={<MdEdit />}
                onClick={() => navigate(`/admin/courses/edit?course=${searchParams.get("course")}`)} // Add your edit handler here
                mx={1}
              />
              <IconButton
                w='56px'
                h='56px'
                aria-label="Delete"
                borderRadius={"full"}
                bg={bgDelButton}
                _hover={bgDelHover}
                icon={<MdDelete />}
                disabled={deleteDisabled}
                onClick={handleDelete} // Add your delete handler here
                mx={1}
              />

            </Flex>
          }
          name={t("course_name")}
          value={courseData.course_name}
        />
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 4 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name={t("enrollee_number")}
          value={courseData.users.length}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdDateRange} color={brandColor} />
              }
            />
          }
          name={t("course_start_date")}
          value={<DateFormatter date={courseData.course_start_date} locale={i18n.language} />}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdDateRange} color={brandColor} />
              }
            />
          }
          name={t("course_end_date")}
          value={<DateFormatter date={courseData.course_end_date} locale={i18n.language} />}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdSafetyCheck} color={brandColor} />
              }
            />
          }
          name={t("course_categories")}
          value={courseData.course_categories.map(si => si.course_category_number).join(', ')}
        />
      </SimpleGrid>

      {
        courseData.users.length ? (
          <ColumnsTable
            columnsData={[
              {
                "Header": "soldier_number",
                "accessor": "soldier_number",
                "show": true,
                "required": false
              },
              {
                "Header": "soldier_full_name",
                "accessor": "soldier_full_name",
                "show": true,
                "required": true
              },
              {
                "Header": "soldier_rank",
                "accessor": "soldier_rank",
                "show": true,
                "required": true
              },
              {
                "Header": "soilder_assign_date",
                "accessor": "soilder_assign_date",
                "show": true,
                "required": true
              },
              {
                "Header": "departments_assigned",
                "accessor": "department",
                "show": true,
                "required": true
              },
              {
                "Header": "course_categories",
                "accessor": "course_categories",
                "show": true,
                "required": true
              },
              {
                "Header": "soilder_status",
                "accessor": "soilder_status",
                "show": true,
                "required": true
              },
            ]}
            tableData={courseData.users}
          />
        ) : (
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 1 }} gap='20px' mb='20px'>
            <Flex justifyContent='center' alignItems='center' h='100%'>
              <MiniStatistics endContent={
                <Flex>
                  <Plus me="10px" href={`/admin/courses/assign?course=${searchParams.get("course")}`} />
                </Flex>
              }
                value={
                  <Text textAlign='center'>{t("no_enrollee")}</Text>
                } />
            </Flex>
          </SimpleGrid>
        )
      }
    </Box>
  );
}
