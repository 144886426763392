// Chakra imports
import { Box, Flex, Spinner, SimpleGrid, Text } from "@chakra-ui/react";
import ColumnsTable from "./ColumnsTable";
import React, { useEffect, useState } from "react";
import axios from 'axios'

// Translation
import { useTranslation } from 'react-i18next';
import MiniStatistics from "components/card/MiniStatistics";
import Plus from "components/buttons/Plus";

export default function Settings() {
  const { t, i18n } = useTranslation()
  const [coursesData, setCoursesData] = useState(null)

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/api/courses`,
    }).then(async (result) => {
      setCoursesData(result.data)

    }).catch(async (err) => {
      console.log(err)
    })
  }, [i18n.language])

  if (!coursesData) return (
    <Flex
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      height="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner
        thickness="8px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl" // You can change this to "lg" or "2xl" if you need it even bigger
      />
    </Flex>
  )

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {
        coursesData.length ? (
          <ColumnsTable
            columnsData={[
              {
                "Header": "course_id",
                "accessor": "course_id",
                "show": true,
                "required": true
              },
              {
                "Header": "course_name",
                "accessor": "course_name",
                "show": true,
                "required": true
              },
              {
                "Header": "course_start_date",
                "accessor": "course_start_date",
                "show": true,
                "required": true
              },
              {
                "Header": "course_end_date",
                "accessor": "course_end_date",
                "show": true,
                "required": true
              },
              {
                "Header": "course_categories",
                "accessor": "course_categories",
                "show": true,
                "required": true
              },
              {
                "Header": "course_status",
                "accessor": "course_status",
                "show": true,
                "required": true
              }
            ]}
            tableData={coursesData}
          />
        ) : (
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 1 }} gap='20px' mb='20px'>
            <Flex justifyContent='center' alignItems='center' h='100%'>
              <MiniStatistics endContent={
                <Flex>
                  <Plus me="10px" href={`/admin/courses/create`} />
                </Flex>
              }
                value={
                  <Text textAlign='center'>{t("no_courses")}</Text>
                } />
            </Flex>
          </SimpleGrid>
        )
      }
    </Box>
  );
}
