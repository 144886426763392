import React, { useEffect, useState } from "react";
// Chakra imports
import { Box, Input, Text, VStack, Flex, useColorModeValue, Button, Spinner, HStack } from "@chakra-ui/react";
import {
  Select,
  useChakraSelectProps,
} from "chakra-react-select";
// Translation
import { useTranslation } from 'react-i18next';
// Axios for HTTP requests
import axios from 'axios';
// React Router
import { useNavigate, useSearchParams } from 'react-router-dom';
// React Toastify for notifications
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Custom components
import Card from "components/card/Card";
import MiniCalendar from "components/calendar/MiniCalendar";
import { AiOutlineConsoleSql } from "react-icons/ai";

export default function Settings() {

  // Chakra Color Mode Variables
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue({ bg: "secondaryGray.400" }, { bg: "whiteAlpha.50" });
  const bgFocus = useColorModeValue({ bg: "secondaryGray.300" }, { bg: "whiteAlpha.100" });

  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [users, setUsers] = useState([]);
  const [usersOption, setUsersOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [soilderStatus, setSoilderStatus] = useState("affiliate");
  const navigate = useNavigate();

  // Fetch users when language changes
  useEffect(() => {

    if (!searchParams.get("course")) return navigate('/admin/courses');

    const fetchUsers = async () => {
      try {
        const usersData = await axios.get('/api/users');
        const courseData = await axios.get(`/api/course/${searchParams.get("course")}`);
    
        // Extract course category numbers from the course data for easier comparison
        const courseCategoryNumbers = courseData.data.course_categories.map(cat => cat.course_category_number);
        
        // Extract soldier numbers of users already assigned to the course
        const assignedUserSoldierNumbers = courseData.data.users.map(user => user.soldier_number);
    
        // Filter users who have at least one matching category and are not in assigned users
        const legitUsers = usersData.data.filter(user => 
          user.course_categories.some(userCat => 
            courseCategoryNumbers.includes(userCat.course_category_number)
          ) && 
          !assignedUserSoldierNumbers.includes(user.soldier_number) // Ensure user is not already assigned
        );
        
        setUsersOptions(legitUsers.map(user => ({
          lable: user.soldier_number,
          value: user.soldier_full_name
        })));

        setLoadingPage(false)
      } catch (error) {
        handleError(error);
      }
    };    

    fetchUsers();
  }, [i18n.language]);

  // Validate form inputs
  const validateForm = () => {
    if (usersOption.length === 0) return toast.error(i18n.language === 'ar' ? 'الرجاء اختيار على الاقل مستخدم واحد.' : 'Please select at least one user to add.');
    return true;
  };

  // Handle errors
  const handleError = (error) => {
    const errorMessage = error.response?.data?.error || error.message;
    console.error("Error creating course:", errorMessage);
    if (errorMessage.includes("duplicate key value violates unique constraint")) {
      toast.error(i18n.language === 'ar' ? "رقم الدورة يجب أن يكون فريدًا ولا يمكن تكراره." : 'The course ID must be unique and cannot be duplicated.')
    } else {
      toast.error(errorMessage);
    }

  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!validateForm()) return;

    setLoading(true);

    try {
      await axios.post(`/api/user/assign/course/${searchParams.get("course")}`, {
        userIds: users,
        soilder_status: soilderStatus,
        soilder_assign_date: new Date()
      });

      // Clear the form fields and redirect
      setUsers([]);
      navigate(`/admin/courses/view?course=${searchParams.get("course")}`);
    } catch (error) {
      console.log(error)
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  if (loadingPage) return (
    <Flex
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      height="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner
        thickness="8px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl" // You can change this to "lg" or "2xl" if you need it even bigger
      />
    </Flex>
  )

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ToastContainer rtl={i18n.language === "ar"} /> {/* Add the ToastContainer to render the toasts */}
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <VStack
          spacing={4}
          align="start"
          w={{ base: "75%", md: "500px" }}
          mx="auto"
          my={4}
        >
          <Box w="100%">
            <Text fontSize="sm" color={inputText} mb={2}>
              {t("soldier_name")}
            </Text>
            <Select
              onChange={(selectedOptions) => {
                const selectedUsers = selectedOptions.map(option => option.value);
                setUsers(selectedUsers); // Set selectedUsers as an array of user values
              }}
              isMulti
              variant='filled'
              fontSize='sm'
              bg={inputBg}
              color={inputText}
              fontWeight='500'
              _placeholder={{ color: "gray.400", fontSize: "14px" }}
              borderRadius={"5px"}
              placeholder={t("please_select")}
              menuPortalTarget={document.body}
              options={usersOption.map(option => ({
                label: option.value,
                value: option.lable,
              }))}
            />
          </Box>
          <Box w="100%">
            <Text fontSize="sm" color={inputText} mb={2}>
              {t("soilder_status")}
            </Text>
            <HStack spacing={0} w="100%">
              <Button
                colorScheme={soilderStatus === "affiliate" ? "green" : "gray"}
                variant={soilderStatus === "affiliate" ? "solid" : "outline"}
                w="33.3%"
                borderRightRadius={0}
                onClick={() => setSoilderStatus("affiliate")}
              >
                {t("soilder_status_affiliate")}
              </Button>
              <Button
                colorScheme={soilderStatus === "candidate" ? "yellow" : "gray"}
                variant={soilderStatus === "candidate" ? "solid" : "outline"}
                w="33.33%"
                borderRadius={0}
                onClick={() => setSoilderStatus("candidate")}
              >
                {t("soilder_status_candidate")}
              </Button>
              <Button
                colorScheme={soilderStatus === "withdrawn" ? "red" : "gray"}
                variant={soilderStatus === "withdrawn" ? "solid" : "outline"}
                w="33.3%"
                borderLeftRadius={0}
                onClick={() => setSoilderStatus("withdrawn")}
              >
                {t("soilder_status_withdrawn")}
              </Button>
            </HStack>
          </Box>
          <Box w="100%">
            <Button
              align='center'
              justifyContent='center'
              bg={bgButton}
              _hover={bgHover}
              _focus={bgFocus}
              _active={bgFocus}
              w='100%'
              h='44px'
              lineHeight='100%'
              borderRadius='5px'
              onClick={handleSubmit}
              disabled={loading} // Disable the button while loading
            >
              {loading ? (
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                />
              ) : (
                <Text fontSize="sm" color={inputText}>
                  {t("assign_soldier")}
                </Text>
              )}
            </Button>
          </Box>
        </VStack>
      </Card>
    </Box>
  );
}
