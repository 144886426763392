import React, { useEffect, useState } from "react";
// Chakra imports
import { Box, Input, Text, VStack, HStack, useColorModeValue, Button, Spinner } from "@chakra-ui/react";
import {
  Select,
  useChakraSelectProps,
} from "chakra-react-select";
// Translation
import { useTranslation } from 'react-i18next';
// Axios for HTTP requests
import axios from 'axios';
// React Router
import { useNavigate, useSearchParams } from 'react-router-dom';
// React Toastify for notifications
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Custom components
import Card from "components/card/Card";
import MiniCalendar from "components/calendar/MiniCalendar";
import { AiOutlineConsoleSql } from "react-icons/ai";

export default function Settings() {

  // Chakra Color Mode Variables
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue({ bg: "secondaryGray.400" }, { bg: "whiteAlpha.50" });
  const bgFocus = useColorModeValue({ bg: "secondaryGray.300" }, { bg: "whiteAlpha.100" });

  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [users, setUsers] = useState([]);
  const [usersOption, setUsersOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Fetch users when language changes
  useEffect(() => {

    const fetchUsers = async () => {
      if (!searchParams.get("course")) {
        return navigate('/admin/courses');
      }

      try {
        const response = await axios.get(`/api/course/${searchParams.get("course")}`);
        setUsersOptions(response.data.users.map(user => ({
          label: user.soldier_number, // Fixing typo from "lable" to "label"
          value: user.soldier_full_name,
        })));
      } catch (error) {
        handleError(error);
      }
    };

    fetchUsers();
  }, [i18n.language]);

  // Validate form inputs
  const validateForm = () => {
    if (usersOption.length === 0) return toast.error(i18n.language === 'ar' ? 'الرجاء اختيار على الاقل مستخدم واحد.' : 'Please select at least one user to add.');
    return true;
  };

  // Handle errors
  const handleError = (error) => {
    const errorMessage = error.response?.data?.error || error.message;
    console.error("Error creating course:", errorMessage);
    if (errorMessage.includes("duplicate key value violates unique constraint")) {
      toast.error(i18n.language === 'ar' ? "رقم الدورة يجب أن يكون فريدًا ولا يمكن تكراره." : 'The course ID must be unique and cannot be duplicated.')
    } else {
      toast.error(errorMessage);
    }

  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      // Send the array of selected soldier IDs in the request body
      await axios.delete(`/api/course/${searchParams.get("course")}/users`, {
        data: { soldier_numbers: users.map(user => user.label) } // Assuming soldier_numbers are represented by label
      });

      // Clear user selection and redirect
      setUsers([]);
      navigate(`/admin/courses/view?course=${searchParams.get("course")}`);
    } catch (error) {
      console.log(error);
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ToastContainer rtl={i18n.language === "ar"} /> {/* Add the ToastContainer to render the toasts */}
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <VStack
          spacing={4}
          align="start"
          w={{ base: "75%", md: "500px" }}
          mx="auto"
          my={4}
        >
          <Box w="100%">
            <Text fontSize="sm" color={inputText} mb={2}>
              {t("soldier_name")}
            </Text>
            <Select
              isMulti
              value={users}
              onChange={(val) => setUsers(val)}
              fontSize='sm'
              bg={inputBg}
              color={inputText}
              fontWeight='500'
              _placeholder={{ color: "gray.400", fontSize: "14px" }}
              borderRadius={"5px"}
              placeholder={t("course_categories")}
              options={usersOption}
            />
          </Box>
          <Box w="100%">
            <Button
              align='center'
              justifyContent='center'
              bg={bgButton}
              _hover={bgHover}
              _focus={bgFocus}
              _active={bgFocus}
              w='100%'
              h='44px'
              lineHeight='100%'
              borderRadius='5px'
              onClick={handleSubmit}
              disabled={loading} // Disable the button while loading
            >
              {loading ? (
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                />
              ) : (
                <Text fontSize="sm" color={inputText}>
                  {t("unassign_soldier")}
                </Text>
              )}
            </Button>
          </Box>
        </VStack>
      </Card>
    </Box>
  );
}
