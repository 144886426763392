// src/components/DateFormatter.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { enUS, arSA } from 'date-fns/locale';

const DateFormatter = ({ date, locale }) => {
  // Ensure date is a Date object
  const dateObject = new Date(date);

  // Format the date based on the locale
  const formattedDate = (locale === 'ar') 
    ? format(dateObject, 'd MMMM yyyy', { locale: arSA }) 
    : format(dateObject, 'MMMM d, yyyy', { locale: enUS });

  return <span>{formattedDate}</span>;
};

// Define prop types
DateFormatter.propTypes = {
  date: PropTypes.string.isRequired,  // Date string
  locale: PropTypes.oneOf(['en', 'ar']).isRequired,  // Locale
};

export default DateFormatter;
