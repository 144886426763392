import React, { useEffect, useState } from "react";
// Chakra imports
import { Box, Input, Text, VStack, HStack, useColorModeValue, Button, Spinner } from "@chakra-ui/react";
import {
  Select,
  useChakraSelectProps,
} from "chakra-react-select";
// Translation
import { useTranslation } from 'react-i18next';
// Axios for HTTP requests
import axios from 'axios';
// React Router
import { useNavigate } from 'react-router-dom';
// React Toastify for notifications
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Custom components
import Card from "components/card/Card";
import DatePicker2 from "../../../../components/datePicker/ReactDatePicker"

export default function Settings() {

  // Chakra Color Mode Variables
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue({ bg: "secondaryGray.400" }, { bg: "whiteAlpha.50" });
  const bgFocus = useColorModeValue({ bg: "secondaryGray.300" }, { bg: "whiteAlpha.100" });

  const { t, i18n } = useTranslation();
  const [courseName, setCourseName] = useState('');
  const [courseId, setCourseId] = useState('');
  const [courseCategory, setCourseCategory] = useState([]);
  const [courseCategoryOptions, setCourseCategoryOptions] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [courseStatus, setCourseStatus] = useState("active");
  const navigate = useNavigate();

  const [courseNameErrored, setCourseNameErrored] = useState(false);
  const [courseIdErrored, setCourseIdErrored] = useState(false);
  const [courseCategoryErrored, setCourseCategoryErrored] = useState(false);
  const [startDateErrored, setStartDateErrored] = useState(false);
  const [endDateErrored, setEndDateErrored] = useState(false);

  // New State for Mandatory Course Logic
  const [isMandatory, setIsMandatory] = useState(false);
  const [targetAudience, setTargetAudience] = useState(null);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState('');
  const [selectedDepartmentErrored, setSelectedDepartmentErrored] = useState(false);
  const [selectedDurationErrored, setSelectedDurationErrored] = useState(false);

  useEffect(() => {
    // Fetch departments if targeting a specific department
    if (targetAudience === "specific") {
      axios.get("/api/departments")
        .then((response) => {
          setDepartmentOptions(response.data.map((dept) => ({
            label: dept.department_name,
            value: dept.department_number,
          })));
        })
        .catch((error) => {
          console.error("Error fetching departments:", error);
          toast.error(t("Failed to load departments"));
        });
    }
  }, [targetAudience]);

  // Fetch course category when language changes
  useEffect(() => {
    const fetchCourseCategory = async () => {
      try {
        const response = await axios.get('/api/course_category');
        setCourseCategoryOptions(response.data.map(si => si.course_category_number));
      } catch (error) {
        handleError(error);
      }
    };

    fetchCourseCategory();
  }, [i18n.language]);

  // Validate form inputs
  const validateForm = () => {
    if (!courseName) {
      toast.error(i18n.language === 'ar' ? 'اسم الدورة مطلوب' : 'Course name is required');
      setCourseNameErrored(true)
      return
    } else setCourseNameErrored(false)

    if (!courseId) {
      toast.error(i18n.language === 'ar' ? 'رقم الدورة مطلوب' : 'Course number is required');
      setCourseIdErrored(true)
      return
    } else setCourseIdErrored(false)

    if (!courseCategory.length) {
      toast.error(i18n.language === 'ar' ? 'السلامة التعريفية مطلوب' : 'Safety Identification is required');
      setCourseCategoryErrored(true)
      return
    } else setCourseCategoryErrored(false)

    if (!startDate) {
      toast.error(i18n.language === 'ar' ? 'تاريخ البدء مطلوب' : 'Start date is required');
      setStartDateErrored(true)
      return
    } else setStartDateErrored(false)

    if (!endDate) {
      toast.error(i18n.language === 'ar' ? 'تاريخ الانتهاء مطلوب' : 'End date is required');
      setEndDateErrored(true)
      return
    } else setEndDateErrored(false)

    if (isMandatory) {
      if (targetAudience.toLowerCase() === "all") {
        if (!selectedDuration) {
          setSelectedDurationErrored(true)
          toast.error(i18n.language === 'ar' ? 'الرجاء اختيار الفترة' : 'Please select a duration.');
          return
        } else setSelectedDurationErrored(false)
      } else {
        if (!selectedDepartment) {
          toast.error(i18n.language === 'ar' ? 'الرجاء اختيار القسم' : 'Please select a department');
          setSelectedDepartmentErrored(true)
          return
        } else setSelectedDepartmentErrored(false)

        if (!selectedDuration) {
          setSelectedDurationErrored(true)
          toast.error(i18n.language === 'ar' ? 'الرجاء اختيار الفترة' : 'Please select a duration.');
          return
        } else setSelectedDurationErrored(false)
      }
    }

    return true;
  };

  // Handle errors
  const handleError = (error) => {
    const errorMessage = error.response?.data?.error || error.message;
    console.error("Error creating course:", errorMessage);
    if (errorMessage.includes("duplicate key value violates unique constraint")) {
      toast.error(i18n.language === 'ar' ? "رقم الدورة يجب أن يكون فريدًا ولا يمكن تكراره." : 'The course ID must be unique and cannot be duplicated.')
    } else {
      toast.error(errorMessage);
    }

  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!validateForm()) return;

    setLoading(true);

    try {
      await axios.post('/api/course', {
        course_id: courseId,
        course_name: courseName,
        course_start_date: startDate,
        course_end_date: endDate,
        course_category_numbers: courseCategory,
        course_creation_date: new Date(),
        course_last_edited_date: new Date(),
        course_status: courseStatus,
        course_mandatory: isMandatory ? {
          duration: selectedDuration.value,
          department: selectedDepartment ? selectedDepartment.value : null
        } : null
      });

      // Clear the form fields and redirect
      setCourseName('');
      setCourseId('');
      setCourseCategory([]);
      setStartDate('');
      setEndDate('');
      navigate('/admin/courses');
    } catch (error) {
      console.log(error)
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ToastContainer rtl={i18n.language === "ar"} /> {/* Add the ToastContainer to render the toasts */}
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <VStack
          spacing={4}
          align="start"
          w={{ base: "75%", md: "500px" }}
          mx="auto"
          my={4}
        >
          <Box w="100%">
            <Text fontSize="sm" color={inputText} mb={2}>
              {t("course_name")} <Text as="span" color="red.500">*</Text>
            </Text>
            <Input
              isInvalid={courseNameErrored}
              errorBorderColor='crimson'
              variant={courseNameErrored ? 'outline' : 'search'}
              value={courseName}
              onChange={(e) => {
                setCourseName(e.target.value)
                setCourseNameErrored(false)
              }}
              fontSize='sm'
              bg={inputBg}
              color={inputText}
              fontWeight='500'
              _placeholder={{ color: "gray.400", fontSize: "14px" }}
              borderRadius={"5px"}
              placeholder={t("course_name")}
            />
          </Box>
          <Box w="100%">
            <Text fontSize="sm" color={inputText} mb={2}>
              {t("course_id")} <Text as="span" color="red.500">*</Text>
            </Text>
            <Input
              isInvalid={courseIdErrored}
              errorBorderColor='crimson'
              variant={courseIdErrored ? 'outline' : 'search'}
              value={courseId}
              onChange={(e) => {
                setCourseId(e.target.value)
                setCourseIdErrored(false)
              }}
              fontSize='sm'
              bg={inputBg}
              color={inputText}
              fontWeight='500'
              _placeholder={{ color: "gray.400", fontSize: "14px" }}
              borderRadius={"5px"}
              placeholder={t("course_id")}
            />
          </Box>
          <Box w="100%">
            <Text fontSize="sm" color={inputText} mb={2}>
              {t("course_categories")} <Text as="span" color="red.500">*</Text>
            </Text>
            <Select
              isInvalid={courseCategoryErrored}
              errorBorderColor='crimson'
              variant={courseCategoryErrored ? 'outline' : 'filled'}
              onChange={(val) => {
                setCourseCategory(val.map(si => si.value))
                setCourseCategoryErrored(false)
              }}
              isMulti
              fontSize='sm'
              bg={inputBg}
              color={inputText}
              fontWeight='500'
              _placeholder={{ color: "gray.400", fontSize: "14px" }}
              borderRadius={"5px"}
              placeholder={t("course_categories")}
              options={courseCategoryOptions.map(option => ({
                label: option,
                value: option,
              }))}
            />

          </Box>

          <Box w="100%">
            <Text fontSize="sm" color={inputText} mb={2}>
              {t("course_status")} <Text as="span" color="red.500">*</Text>
            </Text>
            <HStack spacing={0} w="100%">
              <Button
                colorScheme={courseStatus === "active" ? "blue" : "gray"}
                variant={courseStatus === "active" ? "solid" : "outline"}
                w="33.33%"
                borderRightRadius={0}
                onClick={() => setCourseStatus("active")}
              >
                {t("course_status_active")}
              </Button>
              <Button
                colorScheme={courseStatus === "inactive" ? "red" : "gray"}
                variant={courseStatus === "inactive" ? "solid" : "outline"}
                w="33.33%"
                borderRadius={0}
                onClick={() => setCourseStatus("inactive")}
              >
                {t("course_status_inactive")}
              </Button>
              <Button
                colorScheme={courseStatus === "completed" ? "green" : "gray"}
                variant={courseStatus === "completed" ? "solid" : "outline"}
                w="33.33%"
                borderLeftRadius={0}
                onClick={() => setCourseStatus("completed")}
              >
                {t("course_status_completed")}
              </Button>
            </HStack>
          </Box>
          <Box w="100%">
            <HStack
              w="100%"
            >
              <Box w="100%">
                <Text fontSize="sm" color={startDateErrored ? 'crimson' : inputText} mb={2}>
                  {t("course_start_date")} <Text as="span" color="red.500">*</Text>
                </Text>
                <DatePicker2 selectedDate={startDate} onChange={(date) => setStartDate(date)} />
              </Box>
              <Box w="100%">
                <Text fontSize="sm" color={endDateErrored ? 'crimson' : inputText} mb={2}>
                  {t("course_end_date")} <Text as="span" color="red.500">*</Text>
                </Text>
                <DatePicker2 selectedDate={endDate} onChange={(date) => setEndDate(date)} />
              </Box>
            </HStack>
          </Box>

          <Box w="100%">
            <Text fontSize="sm" color={inputText} mb={2}>
              {t("course_mandatory")} <Text as="span" color="red.500">*</Text>
            </Text>
            <HStack spacing={0} w="100%">
              <Button
                w="50%"
                onClick={() => setIsMandatory(false)}
                colorScheme={isMandatory === false ? "blue" : "gray"}
                borderRightRadius={0}
              >
                {t("not_mandatory")}
              </Button>
              <Button
                w="50%"
                borderLeftRadius={0}
                onClick={() => setIsMandatory(true)}
                colorScheme={isMandatory === true ? "blue" : "gray"}
              >
                {t("mandatory")}
              </Button>
            </HStack>
          </Box>

          {isMandatory && (
            <VStack spacing={4} w="100%">
              <Box w="100%">
                <Text fontSize="sm" color={inputText} mb={2}>
                  {t("select_class")} <Text as="span" color="red.500">*</Text>
                </Text>
                <HStack spacing={0} w="100%">
                  <Button
                    colorScheme={targetAudience === "specific" ? "blue" : "gray"}
                    variant={targetAudience === "specific" ? "solid" : "outline"}
                    w="50%"
                    borderRightRadius={0}
                    onClick={() => setTargetAudience("specific")}
                  >
                    {t("specific_department")}
                  </Button>
                  <Button
                    colorScheme={targetAudience === "all" ? "blue" : "gray"}
                    variant={targetAudience === "all" ? "solid" : "outline"}
                    w="50%"
                    borderLeftRadius={0}
                    onClick={() => setTargetAudience("all")}
                  >
                    {t("everyone")}
                  </Button>
                </HStack>
              </Box>
              {targetAudience === "all" && (
                <Box w="100%">
                  <Text fontSize="sm" color={inputText} mb={2}>
                    {t("select_duration")} <Text as="span" color="red.500">*</Text>
                  </Text>
                  <Select
                    isInvalid={selectedDurationErrored}
                    errorBorderColor='crimson'
                    variant={selectedDurationErrored ? null : 'filled'}
                    fontSize='sm'
                    bg={inputBg}
                    color={inputText}
                    fontWeight='500'
                    _placeholder={{ color: "gray.400", fontSize: "14px" }}
                    borderRadius={"5px"}
                    placeholder={t("select_duration")}
                    menuPortalTarget={document.body}
                    options={[
                      { label: t("lvl2"), value: "2" },
                      { label: t("lvl4"), value: "4" },
                      { label: t("lvl6"), value: "6" },
                      { label: t("lvl8"), value: "8" }
                    ]}
                    value={selectedDuration}
                    onChange={(val) => {
                      setSelectedDuration(val)
                      setSelectedDurationErrored(false)
                    }}
                  />
                </Box>
              )}
              {targetAudience === "specific" && (
                <Box w="100%">
                  <Text fontSize="sm" color={inputText} mb={2}>
                    {t("select_department")} <Text as="span" color="red.500">*</Text>
                  </Text>
                  <Select
                    isInvalid={selectedDepartmentErrored}
                    errorBorderColor='crimson'
                    variant={selectedDepartmentErrored ? null : 'filled'}
                    fontSize='sm'
                    bg={inputBg}
                    color={inputText}
                    fontWeight='500'
                    _placeholder={{ color: "gray.400", fontSize: "14px" }}
                    borderRadius={"5px"}
                    placeholder={t("select_department")}
                    options={departmentOptions}
                    value={selectedDepartment}
                    menuPortalTarget={document.body}
                    onChange={(val) => {
                      setSelectedDepartment(val)
                      setSelectedDepartmentErrored(false)
                    }}
                  />
                  <Text fontSize="sm" color={inputText} mb={2} mt={2}>
                    {t("select_duration")} <Text as="span" color="red.500">*</Text>
                  </Text>
                  <Select
                    isInvalid={selectedDurationErrored}
                    errorBorderColor='crimson'
                    variant={selectedDurationErrored ? null : 'filled'}
                    fontSize='sm'
                    bg={inputBg}
                    color={inputText}
                    fontWeight='500'
                    _placeholder={{ color: "gray.400", fontSize: "14px" }}
                    borderRadius={"5px"}
                    placeholder={t("select_duration")}
                    menuPortalTarget={document.body}
                    options={[
                      { label: t("lvl2"), value: "2" },
                      { label: t("lvl4"), value: "4" },
                      { label: t("lvl6"), value: "6" },
                      { label: t("lvl8"), value: "8" }
                    ]}
                    value={selectedDuration}
                    onChange={(val) => {
                      setSelectedDuration(val)
                      setSelectedDurationErrored(false)
                    }}
                  />
                </Box>
              )}
            </VStack>
          )}
          <Box w="100%">
            <Button
              align='center'
              justifyContent='center'
              bg={bgButton}
              _hover={bgHover}
              _focus={bgFocus}
              _active={bgFocus}
              w='100%'
              h='44px'
              lineHeight='100%'
              borderRadius='5px'
              onClick={handleSubmit}
              disabled={loading} // Disable the button while loading
            >
              {loading ? (
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                />
              ) : (
                <Text fontSize="sm" color={inputText}>
                  {t("create_course")}
                </Text>
              )}
            </Button>
          </Box>
        </VStack>
      </Card>
    </Box>
  );
}