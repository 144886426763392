import React, { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
// import { useColorMode } from "@chakra-ui/react";
import { InputGroup, Input, InputRightElement } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import { useColorModeValue } from "@chakra-ui/react";

import "react-datepicker/dist/react-datepicker.css";
import "./chakra-react-datepicker.css";

const customDateInput = ({ value, onClick, onChange }, ref, inputBg) => {
    return (
        <Input
            variant="filled"
            autoComplete="off"
            background={inputBg}
            color="white"
            value={value}
            ref={ref}
            onClick={onClick}
            onChange={onChange}
        />
    )
}

customDateInput.displayName = "DateInput";

const CustomInput = forwardRef(customDateInput);

const DatePicker2 = ({ selectedDate, onChange, ...props }) => {
    const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
    return (
        <InputGroup className="dark-theme">
            <ReactDatePicker
                selected={selectedDate}
                onChange={onChange}
                className="react-datapicker__input-text"
                customInput={<CustomInput inputBg={inputBg} />}
                dateFormat="MM/dd/yyyy"
                {...props}
            />
        </InputGroup>
    );
};

export default DatePicker2;
