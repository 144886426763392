import React, { useEffect, useState } from "react";
// Chakra imports
import { Box, Input, Text, VStack, HStack, useColorModeValue, Button, Spinner } from "@chakra-ui/react";
// Translation
import { useTranslation } from 'react-i18next';
// Axios for HTTP requests
import axios from 'axios';
// React Router
import { useNavigate, useSearchParams } from 'react-router-dom';
// React Toastify for notifications
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Custom components
import Card from "components/card/Card";

export default function Settings() {

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Chakra Color Mode
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue({ bg: "secondaryGray.400" }, { bg: "whiteAlpha.50" });
  const bgFocus = useColorModeValue({ bg: "secondaryGray.300" }, { bg: "whiteAlpha.100" });

  const { t, i18n } = useTranslation();
  const [departmentName, setDepartmentName] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!departmentName) {
      return toast.error(i18n.language === 'ar' ? 'اسم القسم مطلوب' : 'Department name is required');
    }

    const lang = i18n.language;
    const department = {
      department_name: departmentName,
      number_of_employees_in_department: 0,
    };

    setLoading(true);

    try {
      await axios.post(`/api/edit_department?department_name=${searchParams.get("department_name")}`, {
        lang,
        department
      });
      // Clear the form fields after successful submission
      setDepartmentName('');
      // Redirect to /admin/departments after successful submission
      navigate('/admin/departments');
      setLoading(false);
    } catch (error) {
      console.log(error)
      const errorMessage = error.response?.data.error || error.message;
      console.error("Error creating unit:", errorMessage);
      toast.error(errorMessage); // Display the error message using react-toastify
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchParams.get("department_name")) {
        axios({
            method: 'GET',
            url: `/api/deps?lang=${i18n.language}&department_name=${searchParams.get("department_name")}`,
        }).then(async (result) => {

            if (result.data.rows.length > 0) {
              
                setDepartmentName(result.data.rows[0].department_name)

            } else {
                return navigate('/admin/departments');

            }

        }).catch(async (err) => {
            console.log(err)
            return navigate('/admin/departments');
        })
    } else return navigate('/admin/departments');
}, [i18n.language])

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ToastContainer rtl={i18n.language === "ar"} /> {/* Add the ToastContainer to render the toasts */}
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <VStack
          spacing={4}
          align="start"
          w={{ base: "75%", md: "500px" }}
          mx="auto"
          my={4}
        >
          <Box w="100%">
            <Text fontSize="sm" color={inputText} mb={2}>
              {t("department_name")}
            </Text>
            <Input
              value={departmentName}
              onChange={(e) => setDepartmentName(e.target.value)}
              variant='search'
              fontSize='sm'
              bg={inputBg}
              color={inputText}
              fontWeight='500'
              _placeholder={{ color: "gray.400", fontSize: "14px" }}
              borderRadius={"5px"}
              placeholder={t("department_name")}
            />
          </Box>
          
          <Box w="100%">
            <Button
              align='center'
              justifyContent='center'
              bg={bgButton}
              _hover={bgHover}
              _focus={bgFocus}
              _active={bgFocus}
              w='100%'
              h='44px'
              lineHeight='100%'
              borderRadius='5px'
              onClick={handleSubmit}
              disabled={loading} // Disable the button while loading
            >
              {loading ? (
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                />
              ) : (
                <Text fontSize="sm" color={inputText}>
                  {t("department_edit")}
                </Text>
              )}
            </Button>
          </Box>
        </VStack>
      </Card>
    </Box>
  );
}
