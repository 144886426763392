import React, { useEffect, useState } from "react";
// Chakra imports
import { Box, Input, Text, VStack, HStack, useColorModeValue, Button, Spinner } from "@chakra-ui/react";
import {
  Select,
  useChakraSelectProps,
} from "chakra-react-select";
// Translation
import { useTranslation } from 'react-i18next';
// Axios for HTTP requests
import axios from 'axios';
// React Router
import { useNavigate } from 'react-router-dom';
// React Toastify for notifications
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Custom components
import Card from "components/card/Card";
import DatePicker2 from "../../../../components/datePicker/ReactDatePicker"

export default function Settings() {

  // Chakra Color Mode Variables
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue({ bg: "secondaryGray.400" }, { bg: "whiteAlpha.50" });
  const bgFocus = useColorModeValue({ bg: "secondaryGray.300" }, { bg: "whiteAlpha.100" });

  const { t, i18n } = useTranslation();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [soldierFullName, setSoldierFullName] = useState('');
  const [soldierNumber, setSoldierNumber] = useState('');
  const [soldierRankOptions, setSoldierRankOptions] = useState([
    "جندي",
    "جندي أول",
    "عريف",
    "وكيل رقيب",
    "رقيب",
    "رقيب أول",
    "رئيس رقباء",
    "ملازم",
    "ملازم أول",
    "نقيب",
    "رائد",
    "مقدم",
    "عقيد",
    "عميد",
    "لواء",
    "فريق",
    "فريق أول"
  ]);
  const [soldierRank, setSoldierRank] = useState("")
  const [file, setFile] = useState(null);
  const [soldierDateOfHiring, setSoldierDateOfHiring] = useState(new Date());
  const [soldierCourseCategory, setSoldierCourseCategory] = useState();
  const [soldierCourseCategoryOptions, setSoldierCourseCategoryOptions] = useState([]);
  const [soldierDepartmentsAssigned, setSoldierDepartmentsAssigned] = useState('');
  const [soldierDepartmentsAssignedOptions, setSoldierDepartmentsAssignedOptions] = useState([]);

  const [soldierFullNameErrored, setSoldierFullNameErrored] = useState(false);
  const [soldierNumberErrored, setSoldierNumberErrored] = useState(false);
  const [soldierRankErrored, setSoldierRankErrored] = useState(false);
  const [soldierDateOfHiringErrored, setSoldierDateOfHiringErrored] = useState(false);
  const [courseCategorErrored, setSoldierCourseCategoryErrored] = useState(false);
  const [soldierDepartmentsAssignedErrored, setSoldierDepartmentsAssignedErrored] = useState(false);

  // Fetch course category when language changes
  useEffect(() => {
    const fetchCourseCategory = async () => {
      try {
        const response = await axios.get(`/api/course_category`);
        setSoldierCourseCategoryOptions(response.data.map(course_category => course_category.course_category_number));
      } catch (error) {
        toast.error(error.message);
      }
    };

    fetchCourseCategory();

    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`/api/departments`);
        setSoldierDepartmentsAssignedOptions(response.data.map(dep => dep.department_name));
      } catch (error) {
        toast.error(error.message);
      }
    };

    fetchDepartments();
  }, [i18n.language]);

  // Handle form submission
  const handleSubmit = async () => {
    console.log(soldierCourseCategory)

    // Validate form inputs
    if (!soldierFullName) {
      setSoldierFullNameErrored(true)
      // return toast.error(i18n.language === 'ar' ? 'اسم الكامل للموظف مطلوب' : 'Soldier full name is required');
    } else setSoldierFullNameErrored(false)

    if (!soldierNumber) {
      setSoldierNumberErrored(true)
      // return toast.error(i18n.language === 'ar' ? 'رقم الموظف مطلوب' : 'Soldier number is required');
    } else setSoldierNumberErrored(false)

    if (!soldierRank) {
      setSoldierRankErrored(true)
      // return toast.error(i18n.language === 'ar' ? 'رتبة الموظف مطلوب' : 'Soldier rank is required');
    } else setSoldierRankErrored(false)

    if (!soldierDateOfHiring) {
      setSoldierDateOfHiringErrored(true)
      // return toast.error(i18n.language === 'ar' ? 'تاريخ التعيين مطلوب' : 'Soldier date of hiring is required');
    } else setSoldierDateOfHiringErrored(false)

    if (!soldierCourseCategory) {
      setSoldierCourseCategoryErrored(true)
      // return toast.error(i18n.language === 'ar' ? 'فئة الدورة  مطلوب' : 'Course Category is required');
    } else setSoldierCourseCategoryErrored(false)

    if (!soldierDepartmentsAssigned) {
      setSoldierDepartmentsAssignedErrored(true)
      // return toast.error(i18n.language === 'ar' ? 'تخصص الموظف مطلوب' : 'Soldier department is required');
    } else setSoldierDepartmentsAssignedErrored(false)

    if (soldierFullNameErrored || soldierNumberErrored || soldierRankErrored || soldierDateOfHiringErrored || courseCategorErrored || soldierDepartmentsAssignedErrored) {
      return
    }

    try {

      const uploadData = new FormData();
      uploadData.append('soldier_number', soldierNumber);
      if (file) {
        uploadData.append('soldier_image', file);
      }
      uploadData.append('soldier_full_name', soldierFullName);
      uploadData.append('soldier_rank', soldierRank);
      uploadData.append('soldier_date_of_hiring', soldierDateOfHiring);
      uploadData.append('department_name', soldierDepartmentsAssigned);
      uploadData.append('course_category_numbers', JSON.stringify(soldierCourseCategory));

      setLoading(true)
      await axios.post('/api/user', uploadData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      // Redirect to /admin/soldiers after successful submission
      navigate('/admin/soldiers');

      // Seccessfully created the user
      toast.success(i18n.language === 'ar' ? 'تمت إضافة الجندي بنجاح' : 'Soldier added successfully');
      setLoading(false)

    } catch (error) {
      // if (error.response) if (error.response.data.error.includes("key")) {
      //   setSoldierNumberErrored(true)
      //   setLoading(false)
      //   return toast.error(i18n.language === "ar" ? 'رقم العسكري مستخدم' : "Soldier number is used");
      // }
      console.log(error)

      console.error('Error adding soldier:', error);
      toast.error(error.response ? error.response.data.error : error.messgae);
      setLoading(false)
    }

  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ToastContainer rtl={i18n.language === "ar"} /> {/* Add the ToastContainer to render the toasts */}
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <VStack
          spacing={4}
          align="start"
          w={{ base: "75%", md: "500px" }}
          mx="auto"
          my={4}
        >
          <Box w="100%">
            <Text fontSize="sm" color={inputText} mb={2}>
              {t("soldier_full_name")} <Text as="span" color="red.500">*</Text>
            </Text>
            <Input
              isInvalid={soldierFullNameErrored}
              errorBorderColor='crimson'
              variant={soldierFullNameErrored ? null : 'search'}
              value={soldierFullName}
              onChange={(e) => {
                setSoldierFullName(e.target.value)
                setSoldierFullNameErrored(false)
              }}
              fontSize='sm'
              bg={inputBg}
              color={inputText}
              fontWeight='500'
              _placeholder={{ color: "gray.400", fontSize: "14px" }}
              borderRadius={"5px"}
              placeholder={t("soldier_full_name")}
            />
          </Box>
          <Box w="100%">
            <Text fontSize="sm" color={inputText} mb={2}>
              {t("soldier_number")} <Text as="span" color="red.500">*</Text>
            </Text>
            <Input
              isInvalid={soldierNumberErrored}
              errorBorderColor='crimson'
              variant={soldierNumberErrored ? null : 'search'}
              value={soldierNumber}
              onChange={(e) => {
                setSoldierNumber(e.target.value)
                setSoldierNumberErrored(false)
              }}
              fontSize='sm'
              bg={inputBg}
              color={inputText}
              fontWeight='500'
              _placeholder={{ color: "gray.400", fontSize: "14px" }}
              borderRadius={"5px"}
              placeholder={t("soldier_number")}
            />
          </Box>
          <Box w="100%">
            <Text fontSize="sm" color={inputText} mb={2}>
              {t("soldier_image")}
            </Text>
            <Input
              type="file"
              variant={'search'}
              onChange={(e) => setFile(e.target.files[0])}
              fontSize='sm'
              bg={inputBg}
              color={inputText}
              fontWeight='500'
              _placeholder={{ color: "gray.400", fontSize: "14px" }}
              borderRadius={"5px"}
              placeholder={t("soldier_image")}
            />
          </Box>
          <Box w="100%">
            <Text fontSize="sm" color={inputText} mb={2}>
              {t("soldier_rank")} <Text as="span" color="red.500">*</Text>
            </Text>
            <Select
              isInvalid={soldierRankErrored}
              errorBorderColor='crimson'
              variant={soldierRankErrored ? null : 'filled'}
              onChange={(val) => {
                setSoldierRank(val.value)
                setSoldierRankErrored(false)
              }}
              // isMulti
              fontSize='sm'
              bg={inputBg}
              color={inputText}
              fontWeight='500'
              _placeholder={{ color: "gray.400", fontSize: "14px" }}
              borderRadius={"5px"}
              placeholder={t("soldier_rank")}
              options={soldierRankOptions.map(option => ({
                label: option,
                value: option,
              }))}
            />
          </Box>
          <Box w="100%">
            <Text fontSize="sm" color={inputText} mb={2}>
              {t("soldier_date_of_hiring")} <Text as="span" color="red.500">*</Text>
            </Text>
            <DatePicker2 selectedDate={soldierDateOfHiring} onChange={(date) => setSoldierDateOfHiring(date)} />
          </Box>
          <Box w="100%">
            <Text fontSize="sm" color={inputText} mb={2}>
              {t("course_categories")} <Text as="span" color="red.500">*</Text>
            </Text>
            <Select

              isInvalid={courseCategorErrored}
              errorBorderColor='crimson'
              variant={courseCategorErrored ? null : 'filled'}
              onChange={(val) => {
                setSoldierCourseCategory(val.value)
                setSoldierCourseCategoryErrored(false)
              }}
              // isMulti
              fontSize='sm'
              bg={inputBg}
              color={inputText}
              fontWeight='500'
              _placeholder={{ color: "gray.400", fontSize: "14px" }}
              borderRadius={"5px"}
              placeholder={t("course_categories")}
              options={soldierCourseCategoryOptions.map(option => ({
                label: option,
                value: option,
              }))}
            />
          </Box>
          <Box w="100%">
            <Text fontSize="sm" color={inputText} mb={2}>
              {t("departments_assigned")} <Text as="span" color="red.500">*</Text>
            </Text>
            <Select
              isInvalid={soldierDepartmentsAssignedErrored}
              errorBorderColor='crimson'
              variant={soldierDepartmentsAssignedErrored ? null : 'filled'}
              onChange={(val) => {
                setSoldierDepartmentsAssigned(val.value)
                setSoldierDepartmentsAssignedErrored(false)
              }}
              // isMulti
              fontSize='sm'
              bg={inputBg}
              color={inputText}
              fontWeight='500'
              _placeholder={{ color: "gray.400", fontSize: "14px" }}
              borderRadius={"5px"}
              placeholder={t("departments_assigned")}
              options={soldierDepartmentsAssignedOptions.map(option => ({
                label: option,
                value: option,
              }))}
            />

          </Box>
          <Box w="100%">
            <Button
              align='center'
              justifyContent='center'
              bg={bgButton}
              _hover={bgHover}
              _focus={bgFocus}
              _active={bgFocus}
              w='100%'
              h='44px'
              lineHeight='100%'
              borderRadius='5px'
              onClick={handleSubmit}
              disabled={loading} // Disable the button while loading
            >
              {loading ? (
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                />
              ) : (
                <Text fontSize="sm" color={inputText}>
                  {t("create_soldier")}
                </Text>
              )}
            </Button>
          </Box>
        </VStack>
      </Card>
    </Box>
  );
}
