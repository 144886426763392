import React from "react";

// Chakra imports
import {
  Icon,
  useColorModeValue,
  IconButton,
  Link  // Import Link from Chakra UI
} from "@chakra-ui/react";
// Assets
import {
  MdOutlineMoreHoriz,
  MdRemove
} from "react-icons/md";

export default function Banner(props) {
  const { href, ...rest } = props;  // Add `href` to destructured props

  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  return (
    <Link href={href}>
        <IconButton
          align='center'
          justifyContent='center'
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w='40px'
          h='40px'
          lineHeight='100%'
          borderRadius='10px'
          {...rest}
        >
          <Icon as={MdRemove} color={iconColor} w='24px' h='24px' />
        </IconButton>
      </Link>
  );
}
